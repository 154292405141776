/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useState } from "react";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import clients from "../icons/clients.svg";
import dash from "../icons/dashboard.svg";
import home from "../icons/home.svg";
import importacao from "../icons/importacao.svg";
import photo from "../icons/photos-icon.svg";
import logoFull from "../icons/mooda_logo_full.svg";
import orderIcon from "../icons/orderIcon.svg";
import products from "../icons/products.svg";
import config from "../icons/settings.svg";
import cifrao from "../icons/cifrao.svg";
import mobile from "../icons/mobile.svg";
import discount from "../icons/discount.svg";
import CampaignIcon from "@mui/icons-material/Campaign";
import "./sidebarComponent.css";
import { useCompany } from "../components/context/CompanyContext";
import { urlMooda } from "../requestMethods";

export default function SidebarComponent() {
  const [collapsed, setCollapsed] = useState(false);
  const { slug, showDashboard, enabledPayment } = useCompany();
  const collapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Sidebar
      width="200px"
      collapsed={collapsed}
      collapsedWidth="60px"
      rootStyles={{
        backgroundImage: "linear-gradient( to right, #FF3131, #FF914C )",
        fontWeight: "700",
        color: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          backgroundImage: "linear-gradient( to right, #FF3131, #FF914C )",
          position: "fixed",
          width: "200px",
        }}
      >
        <Menu
          menuItemStyles={{
            icon: { padding: "0px" },
          }}
          rootStyles={{
            backgroundImage: "linear-gradient( to right, #FF3131, #FF914C )",
          }}
        >
          <MenuItem>
            <div className="customLogo">
              <img src={logoFull} />
            </div>
          </MenuItem>
          <MenuItem icon={<img src={home} />} component={<Link to={`home`} />}>
            Home
          </MenuItem>
          <MenuItem
            icon={<img src={clients} />}
            component={<Link to={`company`} />}
          >
            Empresas
          </MenuItem>
          <MenuItem
            icon={<img src={cifrao} />}
            component={<Link to={`chargebackList`} />}
          >
            Chargeback
          </MenuItem>
          <MenuItem
            icon={<img src={importacao} />}
            component={<Link to={`importColors`} />}
          >
            Imp. Cores
          </MenuItem>
        </Menu>
      </div>
    </Sidebar>
  );
}
