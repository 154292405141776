import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import SidebarComponent from "./components/SidebarComponent";
import Topbar from "./components/Topbar";
import Company from "./pages/companies/company";
import CompanyList from "./pages/companies/companyList";
import Home from "./pages/reports/home";
import Login from "./pages/login/login";
import ChargebackList from "./pages/chargeback/chargebackList";
import Chargeback from "./pages/chargeback/chargeback.jsx";
import ImportColors from "./pages/colors/importColors.jsx";

const Layout = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  useEffect(() => {
    if (currentUser) {
      const [_ignoreFirstSlash, rootParam] =
        window.location.pathname.split("/");

      if (rootParam !== "home") {
        var tela = "login";
        localStorage.clear();

        var split = window.location.pathname.toString().split("/");
        var newUrl = window.location.origin + "/" + tela;
        window.location.href = newUrl;
      }
    }
  }, [currentUser]);

  return (
    <div class="wrapper">
      {currentUser && Object.keys(currentUser).length > 0 ? (
        <header>
          <Topbar />
        </header>
      ) : (
        false
      )}
      <article>
        <Outlet />
      </article>
      {currentUser && Object.keys(currentUser).length > 0 ? (
        <aside>
          <SidebarComponent />
        </aside>
      ) : (
        false
      )}
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "company",
        element: <CompanyList />,
      },
      {
        path: "onboarding",
        element: <Company />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "chargebackList",
        element: <ChargebackList />,
      },
      {
        path: "chargeback",
        element: <Chargeback />,
      },
      {
        path: "importColors",
        element: <ImportColors />,
      },
    ],
  },
]);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
